import { getVesselDevices } from 'api/selectedVessel';
import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MyThunkDispatch } from 'store';
import { saveError } from 'store/local/actions';
import { RootState } from 'store/rootReducer';
import { VesselDevice } from 'store/selectedDevice/types';
import { fetchVesselForId } from 'store/selectedVessel/asyncActions';

import { useDateTime } from '../Hooks/useDateTime';

interface GeneratorContextInterface {
  selectedVesselId: string;
  selectedVesselName: string | null;
  loading: boolean;
  handleVesselSelect: (id: string) => void;
  fromDate: Date | null;
  toDate: Date | null;
  fromHours: string;
  toHours: string;
  fromMinutes: string;
  toMinutes: string;
  fromTimeZone: string;
  toTimeZone: string;
  selectedFromDateTime: string | null;
  selectedToDateTime: string | null;
  fromDateTimeISO: string | null;
  toDateTimeISO: string | null;
  isFromDateOpen: boolean;
  isToDateOpen: boolean;
  setFromDate: (date: Date | null) => void;
  setToDate: (date: Date | null) => void;
  setFromHours: (hours: string) => void;
  setToHours: (hours: string) => void;
  setFromMinutes: (minutes: string) => void;
  setToMinutes: (minutes: string) => void;
  setFromTimeZone: (zone: string) => void;
  setToTimeZone: (zone: string) => void;
  setSelectedFromDateTime: (datetime: string | null) => void;
  setSelectedToDateTime: (datetime: string | null) => void;
  setFromDateTimeISO: (iso: string | null) => void;
  setToDateTimeISO: (iso: string | null) => void;
  setIsFromDateOpen: (open: boolean) => void;
  setIsToDateOpen: (open: boolean) => void;
  devicesArray: any[];
}

const GeneratorContext = createContext<GeneratorContextInterface>({
  selectedVesselId: '',
  selectedVesselName: null,
  loading: false,
  handleVesselSelect: () => {
    return;
  },
  fromDate: null,
  toDate: null,
  fromHours: '00',
  toHours: '00',
  fromMinutes: '00',
  toMinutes: '00',
  fromTimeZone: 'Universal',
  toTimeZone: 'Universal',
  selectedFromDateTime: null,
  selectedToDateTime: null,
  fromDateTimeISO: null,
  toDateTimeISO: null,
  isFromDateOpen: false,
  isToDateOpen: false,
  setFromDate: () => {
    return;
  },
  setToDate: () => {
    return;
  },
  setFromHours: () => {
    return;
  },
  setToHours: () => {
    return;
  },
  setFromMinutes: () => {
    return;
  },
  setToMinutes: () => {
    return;
  },
  setFromTimeZone: () => {
    return;
  },
  setToTimeZone: () => {
    return;
  },
  setSelectedFromDateTime: () => {
    return;
  },
  setSelectedToDateTime: () => {
    return;
  },
  setFromDateTimeISO: () => {
    return;
  },
  setToDateTimeISO: () => {
    return;
  },
  setIsFromDateOpen: () => {
    return;
  },
  setIsToDateOpen: () => {
    return;
  },
  devicesArray: [],
});

const GeneratorContextProvider = (props: { children: JSX.Element }): JSX.Element => {
  const location = useLocation();
  const { vesselsList } = useSelector((state: RootState) => state.vessels);
  const [selectedVesselId, setSelectedVesselId] = useState<string>('');
  const [selectedVesselName, setSelectedVesselName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [devicesArray, setDevicesArray] = useState<any[]>([]);

  const {
    fromDate,
    setFromDate,
    toDate,
    setToDate,
    fromHours,
    setFromHours,
    toHours,
    setToHours,
    fromMinutes,
    setFromMinutes,
    toMinutes,
    setToMinutes,
    fromTimeZone,
    setFromTimeZone,
    toTimeZone,
    setToTimeZone,
    selectedFromDateTime,
    setSelectedFromDateTime,
    selectedToDateTime,
    setSelectedToDateTime,
    fromDateTimeISO,
    setFromDateTimeISO,
    toDateTimeISO,
    setToDateTimeISO,
    isFromDateOpen,
    setIsFromDateOpen,
    isToDateOpen,
    setIsToDateOpen,
  } = useDateTime();

  const dispatch: MyThunkDispatch = useDispatch();

  function loadSelectedVesselData(id: string): void {
    if (id) {
      setLoading(true);
      dispatch(fetchVesselForId(id)).then(() => {
        setLoading(false);
      });
    }
  }

  function handleVesselSelect(id: string): void {
    setDevicesArray([]);
    setSelectedVesselId(id);
    loadSelectedVesselData(id);
    const vessel = vesselsList.find(vessel => vessel.id === id);
    if (vessel) {
      setSelectedVesselName(vessel.name);
    } else {
      setSelectedVesselName(null);
    }
  }

  useEffect(() => {
    if (selectedVesselId) {
      setLoading(true);
      getVesselDevices(selectedVesselId)
        .then(res => {
          const tempArray: any[] = [];
          res.data.forEach((device: VesselDevice) => {
            if (
              device.name.toLowerCase().includes('engine') ||
              device.name.toLowerCase().includes('generator')
            ) {
              tempArray.push({ id: device.id, name: device.name });
            }
          });
          setDevicesArray(tempArray);
        })
        .catch(err => dispatch(saveError(err)))
        .finally(() => setLoading(false));
    }
  }, [selectedVesselId]);

  useEffect(() => {
    setSelectedVesselId('');
    setSelectedVesselName(null);
    setDevicesArray([]);
    setFromDate(null);
    setToDate(null);
    setFromHours('00');
    setToHours('00');
    setFromMinutes('00');
    setToMinutes('00');
    setFromTimeZone('Universal');
    setToTimeZone('Universal');
    setSelectedFromDateTime(null);
    setSelectedToDateTime(null);
    setFromDateTimeISO(null);
    setToDateTimeISO(null);
    setIsFromDateOpen(false);
    setIsToDateOpen(false);
  }, [location.pathname]);

  return (
    <GeneratorContext.Provider
      value={{
        selectedVesselId,
        selectedVesselName,
        loading,
        handleVesselSelect,
        fromDate,
        toDate,
        fromHours,
        toHours,
        fromMinutes,
        toMinutes,
        fromTimeZone,
        toTimeZone,
        selectedFromDateTime,
        selectedToDateTime,
        fromDateTimeISO,
        toDateTimeISO,
        isFromDateOpen,
        isToDateOpen,
        setFromDate,
        setToDate,
        setFromHours,
        setToHours,
        setFromMinutes,
        setToMinutes,
        setFromTimeZone,
        setToTimeZone,
        setSelectedFromDateTime,
        setSelectedToDateTime,
        setFromDateTimeISO,
        setToDateTimeISO,
        setIsFromDateOpen,
        setIsToDateOpen,
        devicesArray,
      }}>
      {props.children}
    </GeneratorContext.Provider>
  );
};

export { GeneratorContext, GeneratorContextProvider };
