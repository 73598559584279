import { SimpleDeviceFieldWrapper } from '../store/simpleDevice/types';

export enum SimpleDeviceType {
  MFD,
  Client,
  Cloud,
}

export interface SimpleDeviceLocationResponse {
  name: string;
  statusValue: string;
  devices: SimpleDevice[];
  activeCount: number;
  online: boolean;
  warningState: string;
}

export interface SimpleDevice {
  id: number;
  name: string;
  state: string;
  manufacturer: string;
  model: string;
  serial: string;
  system: string;
  subSystem: string;
  controllable: boolean;
  online: boolean;
  displayComponent: string;
  location: string;
  templateName: string;
  warningState: 'CRITICAL' | 'WARNING' | 'NORMAL' | 'WHITE' | 'GREY' | 'TRANSPARENT';
  fields: SimpleDeviceFieldWrapper[];
  metaData: { capacity: number | string };
  friendlyName: string;
}

export interface SimpleDeviceTemplate {
  id: number;
  name: string;
  system: string;
  subSystem: string;
  controllable: boolean;
  displayComponent: string;
  fields: SimpleDeviceFieldWrapper[];
}

export type CustomDictionaryKey = { value: string; displayValue: string };

export interface TankGroup {
  items: SimpleDevice[];
  name: string;
  percentage: number;
  tankCount: number;
  totalCapacity: number;
  totalVolume: number;
  warningState: string;
  volumeUnit: string;
}

export type SimpleDeviceGaugeField = {
  dpvhId: number;
  value: string;
  loggable: boolean;
  unit: string;
  displayName: string;
  deviceOnline: boolean;
  warnLevelLow: number | 'NOT SET';
  warnLevelHigh: number | 'NOT SET';
  criticalLevelLow: number | 'NOT SET';
  criticalLevelHigh: number | 'NOT SET';
  gaugeLow: number;
  gaugeHigh: number;
  warningState: string;
  dpvhName: string;
};

export type SimpleValueField = { value: number; parsedValue: string; deviceOnline: boolean };

export type ChartData = SimpleDeviceFieldWrapper[] | SimpleDeviceGaugeField[] | null | undefined;

export type SimpleField = {
  dpvhId: number;
  value: number;
  unit: string;
  gaugeLow: number;
  gaugeHigh: number;
  displayName: string;
  dpvhName: string;
};
