import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deleteVesselRoutine, getCameras } from 'api/selectedVessel';
import { AppThunk, MyThunkDispatch } from 'store';
import { fetchAllDeviceManufacturers } from 'store/manufacturers/actions';
import { VesselProduct } from 'store/profile/types';
import { Vessel } from 'store/vessels/types';
import { CameraDTO } from 'types/security';

import {
  initVesselDashboardPermissionContext,
  VesselDashboardPermissionContext,
} from '../../views/VesselDashboard/permissionsContext';
import { fetchFailed, fetchStart, loadingSuccess } from '../helpers';
import { DeviceProperty, VesselDevice } from '../selectedDevice/types';
import { fetchSimpleDevicesFlat } from '../simpleDevice';
import { ModuleState, Note } from '../types';
import {
  fetchControllers,
  fetchFilteredProperties,
  fetchGeofenceParams,
  fetchInterfaces,
  fetchVesselAlerts,
  fetchVesselAudits,
  fetchVesselAuditsPaginated,
  fetchVesselBusList,
  fetchVesselDevices,
  fetchVesselDirectory,
  fetchVesselDocuments,
  fetchVesselForId,
  fetchVesselLocationHistory,
  fetchVesselNotes,
  fetchVesselPermissions,
  fetchVesselProducts,
  fetchVesselRoutines,
  fetchVesselUserNotificationStatus,
  fetchVesselWeather,
} from './asyncActions';
import { selectedVesselDelayLoading } from './provider';
import {
  Alerts,
  Bus,
  Controller,
  ControllerInterface,
  GeofenceParams,
  VesselAuditPage,
  VesselDirectoryResponse,
  VesselDocument,
  VesselLocationHistory,
  VesselLog,
  VesselPermissionsResponse,
  VesselRoutine,
  VesselWeatherInfo,
} from './types';

export type VesselDashboardTabs =
  | 'OVERVIEW'
  | 'DEVICES'
  | 'DOCUMENTS'
  | 'LOGS'
  | 'ROUTINES'
  | 'ACCESS'
  | 'ALERTS'
  | 'NOTES'
  | 'SECURITY';

interface SelectedVesselState extends ModuleState {
  selectedTab: VesselDashboardTabs;
  selectedVessel: Vessel | null;
  devices: VesselDevice[];
  weather: VesselWeatherInfo | null;
  selectedDevice: VesselDevice | null;
  directory: VesselDirectoryResponse[] | null;
  alerts: Alerts[] | null;
  routines: VesselRoutine[] | null;
  permissions: VesselPermissionsResponse[] | null;
  documents: VesselDocument[] | null;
  audits: VesselLog[] | null;
  auditsPaginated: VesselAuditPage | null;
  notes: Note[];
  userNotificationStatus: boolean;
  vesselBusList: Bus[];
  filteredPropertyList: DeviceProperty[];
  products: VesselProduct[];
  locationHistory: VesselLocationHistory[];
  geoFenceParams: GeofenceParams | null;
  cameras: CameraDTO[];
  groupName: string;
  controllers: Controller[];
  interfaces: ControllerInterface[];
  currentUserPerms: VesselDashboardPermissionContext;
  camerasLoading: boolean;
  geoLoading: boolean;

  routinesRequested: boolean;
  alertsRequested: boolean;
  controllersRequested: boolean;
  interfacesRequested: boolean;
  permissionsRequested: boolean;
  notesRequested: boolean;
  logsRequested: boolean;
  devicesRequested: boolean;

  devicesLoading: boolean;
  routinesLoading: boolean;
  alertsLoading: boolean;
  controllersLoading: boolean;
  interfacesLoading: boolean;
  notesLoading: boolean;
  permissionsLoading: boolean;
  logsLoading: boolean;

  devicesLoadingForcing: boolean;
  routinesLoadingForcing: boolean;
  alertsLoadingForcing: boolean;
  controllersLoadingForcing: boolean;
  interfacesLoadingForcing: boolean;
  notesLoadingForcing: boolean;
  logsLoadingForcing: boolean;
}

const selectedVesselInitialState: SelectedVesselState = {
  selectedTab: 'OVERVIEW',
  selectedVessel: null,
  weather: null,
  devices: [],
  selectedDevice: null,
  directory: null,
  alerts: null,
  routines: null,
  permissions: null,
  documents: null,
  audits: null,
  auditsPaginated: null,
  // subscription: null,
  notes: [],
  userNotificationStatus: false,
  isLoading: false,
  error: null,
  vesselBusList: [],
  filteredPropertyList: [],
  products: [],
  locationHistory: [],
  geoFenceParams: null,
  cameras: [],
  groupName: '',
  controllers: [],
  interfaces: [],
  currentUserPerms: initVesselDashboardPermissionContext,
  camerasLoading: false,
  geoLoading: false,

  devicesRequested: false,
  permissionsRequested: false,
  routinesRequested: false,
  alertsRequested: false,
  controllersRequested: false,
  interfacesRequested: false,
  notesRequested: false,
  logsRequested: false,

  devicesLoading: false,
  routinesLoading: false,
  alertsLoading: false,
  controllersLoading: false,
  interfacesLoading: false,
  notesLoading: false,
  permissionsLoading: false,
  logsLoading: false,

  devicesLoadingForcing: false,
  routinesLoadingForcing: false,
  alertsLoadingForcing: false,
  controllersLoadingForcing: false,
  interfacesLoadingForcing: false,
  notesLoadingForcing: false,
  logsLoadingForcing: false,
};
const selectedVessel = createSlice({
  name: 'selectedVessel',
  initialState: selectedVesselInitialState,
  reducers: {
    fetchSelectedVesselStart: fetchStart,
    fetchSelectedVesselFailure: fetchFailed,
    fetchSelectedVesselSuccess: loadingSuccess,
    changeVesselDashboardTab(state, { payload }: PayloadAction<VesselDashboardTabs>) {
      state.selectedTab = payload;
    },
    loadAllVesselData(state, { payload }: PayloadAction<Vessel>) {
      if (payload) {
        state.selectedVessel = payload;
      }
    },
    fetchCamerasSuccess(state, { payload }: PayloadAction<CameraDTO[]>) {
      state.cameras = payload;
    },
    storeGroupByNameSuccess(state, { payload }: PayloadAction<string>) {
      state.groupName = payload;
    },
    selectedVesselSliceReset: () => selectedVesselInitialState,
  },
  extraReducers: builder => {
    builder.addCase(fetchVesselForId.pending, state => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
    });
    builder.addCase(fetchVesselAlerts.pending, state => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.alertsRequested = true;
    });
    builder.addCase(fetchVesselAlerts.typePrefix + '_LOADING_START', state => {
      state.alertsLoadingForcing = true;
      if (state.alertsRequested) {
        state.alertsLoading = true;
      }
    });
    builder.addCase(fetchVesselAlerts.typePrefix + '_FORCE_LOADING', state => {
      selectedVesselDelayLoading.clearForceLoadingTimeout();
      if (!state.alertsRequested) {
        state.alertsLoading = false;
        state.alertsLoadingForcing = false;
      }
    });
    builder.addCase(fetchVesselPermissions.pending, state => {
      if (!state.isLoading) {
        state.isLoading = true;
      }
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.permissionsRequested = true;
    });
    builder.addCase(fetchVesselPermissions.typePrefix + '_LOADING_START', state => {
      if (state.permissionsRequested) {
        state.permissionsLoading = true;
      }
    });
    builder.addCase(fetchVesselRoutines.pending, state => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.routinesRequested = true;
    });
    builder.addCase(fetchVesselRoutines.typePrefix + '_LOADING_START', state => {
      state.routinesLoadingForcing = true;
      if (state.routinesRequested) {
        state.routinesLoading = true;
      }
    });
    builder.addCase(fetchVesselRoutines.typePrefix + '_FORCE_LOADING', state => {
      selectedVesselDelayLoading.clearForceLoadingTimeout();
      if (!state.routinesRequested) {
        state.routinesLoading = false;
        state.routinesLoadingForcing = false;
      }
    });
    builder.addCase(fetchVesselForId.fulfilled, (state, { payload }: PayloadAction<Vessel>) => {
      if (payload) {
        state.selectedVessel = payload;
      }
      state.isLoading = false;
    });
    builder.addCase(fetchVesselForId.rejected, state => {
      if (state.isLoading) {
        state.isLoading = false;
      }
    });
    builder.addCase(
      fetchVesselAudits.fulfilled,
      (state, { payload }: PayloadAction<VesselLog[]>) => {
        state.audits = payload;
      }
    );
    builder.addCase(fetchVesselAuditsPaginated.pending, state => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.logsRequested = true;
    });
    builder.addCase(fetchVesselAuditsPaginated.typePrefix + '_LOADING_START', state => {
      state.logsLoadingForcing = true;
      if (state.logsRequested) {
        state.logsLoading = true;
      }
    });
    builder.addCase(fetchVesselAuditsPaginated.typePrefix + '_FORCE_LOADING', state => {
      selectedVesselDelayLoading.clearForceLoadingTimeout();
      if (!state.logsRequested) {
        state.logsLoading = false;
        state.logsLoadingForcing = false;
      }
    });
    builder.addCase(
      fetchVesselAuditsPaginated.fulfilled,
      (state, { payload }: PayloadAction<VesselAuditPage>) => {
        selectedVesselDelayLoading.clearLoadingTimeout();
        state.logsRequested = false;
        if (payload) {
          state.auditsPaginated = payload;
        }
        if (!state.logsLoadingForcing) {
          selectedVesselDelayLoading.clearForceLoadingTimeout();
          state.logsLoading = false;
        }
      }
    );
    builder.addCase(fetchControllers.pending, state => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.controllersRequested = true;
    });
    builder.addCase(fetchControllers.typePrefix + '_LOADING_START', state => {
      state.controllersLoadingForcing = true;
      if (state.controllersRequested) {
        state.controllersLoading = true;
      }
    });
    builder.addCase(fetchControllers.typePrefix + '_FORCE_LOADING', state => {
      selectedVesselDelayLoading.clearForceLoadingTimeout();
      if (!state.controllersRequested) {
        state.controllersLoading = false;
        state.controllersLoadingForcing = false;
      }
    });
    builder.addCase(fetchInterfaces.pending, state => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.interfacesRequested = true;
    });
    builder.addCase(fetchInterfaces.typePrefix + '_LOADING_START', state => {
      state.interfacesLoadingForcing = true;
      if (state.interfacesRequested) {
        state.interfacesLoading = true;
      }
    });
    builder.addCase(fetchInterfaces.typePrefix + '_FORCE_LOADING', state => {
      selectedVesselDelayLoading.clearForceLoadingTimeout();
      if (!state.interfacesRequested) {
        state.interfacesLoading = false;
        state.interfacesLoadingForcing = false;
      }
    });
    builder.addCase(fetchVesselNotes.pending, state => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.notesRequested = true;
    });
    builder.addCase(fetchVesselNotes.typePrefix + '_LOADING_START', state => {
      state.notesLoadingForcing = true;
      if (state.notesRequested) {
        state.notesLoading = true;
      }
    });
    builder.addCase(fetchVesselNotes.typePrefix + '_FORCE_LOADING', state => {
      selectedVesselDelayLoading.clearForceLoadingTimeout();
      if (!state.notesRequested) {
        state.notesLoading = false;
        state.notesLoadingForcing = false;
      }
    });
    builder.addCase(fetchGeofenceParams.pending, state => {
      if (!state.geoLoading) {
        state.geoLoading = true;
      }
    });
    builder.addCase(fetchVesselAlerts.fulfilled, (state, { payload }: PayloadAction<Alerts[]>) => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.alertsRequested = false;
      if (payload) {
        state.alerts = payload;
      }
      if (!state.alertsLoadingForcing) {
        selectedVesselDelayLoading.clearForceLoadingTimeout();
        state.alertsLoading = false;
      }
    });
    builder.addCase(
      fetchVesselRoutines.fulfilled,
      (state, { payload }: PayloadAction<VesselRoutine[]>) => {
        selectedVesselDelayLoading.clearLoadingTimeout();
        state.routinesRequested = false;
        state.routines = payload;
        if (!state.routinesLoadingForcing) {
          selectedVesselDelayLoading.clearForceLoadingTimeout();
          state.routinesLoading = false;
        }
      }
    );
    builder.addCase(
      fetchVesselPermissions.fulfilled,
      (state, { payload }: PayloadAction<VesselPermissionsResponse[]>) => {
        selectedVesselDelayLoading.clearLoadingTimeout();
        state.permissionsRequested = false;
        state.permissionsLoading = false;
        state.permissions = payload;
        state.isLoading = false;
      }
    );
    builder.addCase(fetchControllers.fulfilled, (state, { payload }: PayloadAction<any[]>) => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.controllersRequested = false;
      state.controllers = payload;
      if (!state.controllersLoadingForcing) {
        selectedVesselDelayLoading.clearForceLoadingTimeout();
        state.controllersLoading = false;
      }
    });
    builder.addCase(
      fetchInterfaces.fulfilled,
      (state, { payload }: PayloadAction<ControllerInterface[]>) => {
        selectedVesselDelayLoading.clearLoadingTimeout();
        state.interfacesRequested = false;
        state.interfaces = payload;
        if (!state.interfacesLoadingForcing) {
          selectedVesselDelayLoading.clearForceLoadingTimeout();
          state.interfacesLoading = false;
        }
      }
    );
    builder.addCase(
      fetchVesselDirectory.fulfilled,
      (state, { payload }: PayloadAction<VesselDirectoryResponse[]>) => {
        state.directory = payload;
      }
    );
    builder.addCase(
      fetchVesselDocuments.fulfilled,
      (state, { payload }: PayloadAction<VesselDocument[]>) => {
        state.documents = payload;
      }
    );
    builder.addCase(fetchVesselNotes.fulfilled, (state, { payload }: PayloadAction<Note[]>) => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.notesRequested = false;
      state.notes = payload;
      if (!state.notesLoadingForcing) {
        selectedVesselDelayLoading.clearForceLoadingTimeout();
        state.notesLoading = false;
      }
    });
    builder.addCase(
      fetchVesselWeather.fulfilled,
      (state, { payload }: PayloadAction<VesselWeatherInfo>) => {
        state.weather = payload;
      }
    );
    builder.addCase(fetchVesselBusList.fulfilled, (state, { payload }: PayloadAction<Bus[]>) => {
      state.vesselBusList = payload;
    });
    builder.addCase(
      fetchFilteredProperties.fulfilled,
      (state, { payload }: PayloadAction<DeviceProperty[]>) => {
        state.filteredPropertyList = payload;
      }
    );
    builder.addCase(
      fetchVesselUserNotificationStatus.fulfilled,
      (state, { payload }: PayloadAction<boolean>) => {
        state.userNotificationStatus = payload;
      }
    );
    builder.addCase(
      fetchVesselProducts.fulfilled,
      (state, { payload }: PayloadAction<VesselProduct[]>) => {
        state.products = payload;
      }
    );
    builder.addCase(
      fetchVesselLocationHistory.fulfilled,
      (state, { payload }: PayloadAction<VesselLocationHistory[]>) => {
        state.locationHistory = payload;
      }
    );
    builder.addCase(
      fetchGeofenceParams.fulfilled,
      (state, { payload }: PayloadAction<GeofenceParams>) => {
        state.geoFenceParams = payload;
        state.geoLoading = false;
      }
    );
    builder.addCase(fetchVesselDevices.pending, state => {
      selectedVesselDelayLoading.clearLoadingTimeout();
      state.devicesRequested = true;
    });
    builder.addCase(fetchVesselDevices.typePrefix + '_LOADING_START', state => {
      state.devicesLoadingForcing = true;
      if (state.devicesRequested) {
        state.devicesLoading = true;
      }
    });
    builder.addCase(fetchVesselDevices.typePrefix + '_FORCE_LOADING', state => {
      selectedVesselDelayLoading.clearForceLoadingTimeout();
      if (!state.devicesRequested) {
        state.devicesLoading = false;
        state.devicesLoadingForcing = false;
      }
    });
    builder.addCase(
      fetchVesselDevices.fulfilled,
      (state, { payload }: PayloadAction<VesselDevice[]>) => {
        selectedVesselDelayLoading.clearLoadingTimeout();
        state.devicesRequested = false;
        state.devices = payload;
        if (!state.devicesLoadingForcing) {
          selectedVesselDelayLoading.clearForceLoadingTimeout();
          state.devicesLoading = false;
        }
      }
    );
  },
});

export const {
  fetchSelectedVesselStart,
  fetchSelectedVesselFailure,
  fetchSelectedVesselSuccess,
  changeVesselDashboardTab,
  fetchCamerasSuccess,
  storeGroupByNameSuccess,
  selectedVesselSliceReset,
} = selectedVessel.actions;

export default selectedVessel.reducer;

const storeLoader = async (
  dispatch: MyThunkDispatch,
  storeReducer: () => Promise<void>
): Promise<any> => {
  try {
    dispatch(fetchSelectedVesselStart());
    await storeReducer();
  } catch (err) {
    dispatch(fetchSelectedVesselFailure(err.toString()));
    return err;
  }
};

export const fetchRoutineData =
  (vesselId: string, alert?: boolean): AppThunk =>
  async dispatch => {
    storeLoader(dispatch, async () => {
      await Promise.all([
        dispatch(fetchVesselForId(vesselId)),
        dispatch(fetchSimpleDevicesFlat(vesselId)),
        dispatch(fetchVesselDirectory(vesselId)),
        alert ? dispatch(fetchVesselAlerts(vesselId)) : dispatch(fetchVesselRoutines(vesselId)),
      ]);
    });
  };

export const fetchVesselDeviceData =
  (vesselId: string): AppThunk =>
  async dispatch => {
    storeLoader(dispatch, async () => {
      await Promise.all([
        dispatch(fetchVesselForId(vesselId)),
        dispatch(fetchVesselBusList(vesselId)),
        dispatch(fetchAllDeviceManufacturers()),
      ]);

      dispatch(fetchSelectedVesselSuccess());
    });
  };

export const fetchDevicesData =
  (vesselId: string): AppThunk =>
  async dispatch => {
    storeLoader(dispatch, async () => {
      await Promise.all([
        dispatch(fetchVesselBusList(vesselId)),
        dispatch(fetchAllDeviceManufacturers()),
      ]);

      dispatch(fetchSelectedVesselSuccess());
    });
  };

export const removeVesselRoutine =
  (vesselId: string, routineId: string): AppThunk =>
  async dispatch => {
    storeLoader(dispatch, async () => {
      await deleteVesselRoutine(vesselId, routineId);
      await dispatch(fetchVesselRoutines(vesselId));
      dispatch(fetchSelectedVesselSuccess());
    });
  };

export const removeVesselAlert =
  (vesselId: string, routineId: string): AppThunk =>
  async dispatch => {
    storeLoader(dispatch, async () => {
      await deleteVesselRoutine(vesselId, routineId);
      await dispatch(fetchVesselAlerts(vesselId));
      dispatch(fetchSelectedVesselSuccess());
    });
  };

export const fetchCameras =
  (vesselId: string): AppThunk =>
  async dispatch => {
    storeLoader(dispatch, async () => {
      const response = await getCameras(vesselId);
      dispatch(fetchCamerasSuccess(response.data));
    });
  };

export const storeGroupName =
  (groupName: string): AppThunk =>
  async dispatch => {
    storeLoader(dispatch, async () => {
      dispatch(storeGroupByNameSuccess(groupName));
    });
  };

export const fetchAllVesselData = (): AppThunk => async dispatch => {
  //empty
};
