import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getBusProperties,
  getControllers,
  getInterfaces,
  getUserPerms,
  getVesselAlerts,
  getVesselAudit,
  getVesselAuditPaginated,
  GetVesselAuditPaginatedInterface,
  getVesselBusList,
  getVesselDevices,
  getVesselDirectory,
  getVesselDocuments,
  getVesselForId,
  getVesselGeofenceParams,
  getVesselLocationHistory,
  getVesselNotes,
  getVesselPermissions,
  getVesselProducts,
  getVesselRoutines,
  getVesselUserNotificationStatus,
  getVesselWeather,
  postSetGpsReporting,
  postSetMfrAccess,
  postSetUserNotificationsForVessel,
  postSetVesselPropertyUpdateEnable,
} from 'api/selectedVessel';
import axios from 'axios';

import { selectedVesselDelayLoading } from './provider';

export const fetchVesselForId = createAsyncThunk(
  'selectedVessel/fetchVesselForId',
  async (vesselId: string, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    try {
      const response = await getVesselForId(vesselId, source.token);
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchUserPerms = createAsyncThunk(
  'selectedVessel/fetchUserPerms',
  async (vesselId: string, { signal, rejectWithValue }) => {
    const source = axios.CancelToken.source();
    signal.addEventListener('abort', () => {
      source.cancel();
    });
    try {
      const userResponse = await getUserPerms(vesselId);
      return userResponse.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const fetchVesselDevices = createAsyncThunk(
  'selectedVessel/fetchVesselDevices',
  async (vesselId: string) => {
    const response = await getVesselDevices(vesselId);
    return response.data;
  }
);

export const fetchVesselAudits = selectedVesselDelayLoading.delayLoadingAsyncThunk(
  'selectedVessel/fetchVesselAudits',
  async (vesselId: string) => {
    const response = await getVesselAudit(vesselId);
    return response.data;
  }
);

export const fetchVesselAuditsPaginated = createAsyncThunk(
  'selectedVessel/fetchVesselAuditsPaginated',
  async ({ vesselId, data }: { vesselId: string; data: GetVesselAuditPaginatedInterface }) => {
    const response = await getVesselAuditPaginated(data, vesselId);
    return response.data;
  }
);

export const fetchVesselAlerts = selectedVesselDelayLoading.delayLoadingAsyncThunk(
  'selectedVessel/fetchVesselAlerts',
  async (vesselId: string) => {
    const response = await getVesselAlerts(vesselId);
    return response.data;
  }
);
export const fetchVesselRoutines = selectedVesselDelayLoading.delayLoadingAsyncThunk(
  'selectedVessel/fetchVesselRoutines',
  async (vesselId: string) => {
    const response = await getVesselRoutines(vesselId);
    return response.data;
  }
);
export const fetchVesselPermissions = createAsyncThunk(
  'selectedVessel/fetchVesselPermissions',
  async (vesselId: string) => {
    const response = await getVesselPermissions(vesselId);
    return response.data;
  }
);
export const fetchControllers = selectedVesselDelayLoading.delayLoadingAsyncThunk(
  'selectedVessel/fetchControllers',
  async (vesselId: string) => {
    const response = await getControllers(vesselId);
    return response.data;
  }
);
export const fetchInterfaces = selectedVesselDelayLoading.delayLoadingAsyncThunk(
  'selectedVessel/fetchInterfaces',
  async (vesselId: string) => {
    const response = await getInterfaces(vesselId);
    return response.data;
  }
);
export const fetchVesselDirectory = createAsyncThunk(
  'selectedVessel/fetchVesselDirectory',
  async (vesselId: string) => {
    const response = await getVesselDirectory(vesselId);
    return response.data;
  }
);
export const fetchVesselDocuments = createAsyncThunk(
  'selectedVessel/fetchVesselDocuments',
  async (vesselId: string) => {
    const response = await getVesselDocuments(vesselId);
    return response.data;
  }
);

export const fetchVesselWeather = createAsyncThunk(
  'selectedVessel/fetchVesselWeather',
  async ({ lat, lon }: { lat: string; lon: string }) => {
    const response = await getVesselWeather(lat, lon);
    return response.data;
  }
);

export const fetchVesselUserNotificationStatus = createAsyncThunk(
  'selectedVessel/fetchVesselUserNotificationStatus',
  async (id: string) => {
    const response = await getVesselUserNotificationStatus(id);
    return response.data;
  }
);

export const fetchVesselNotes = selectedVesselDelayLoading.delayLoadingAsyncThunk(
  'selectedVessel/fetchVesselNotes',
  async (id: string) => {
    const response = await getVesselNotes(id);
    return response.data;
  }
);

export const fetchVesselProducts = createAsyncThunk(
  'selectedVessel/fetchVesselProducts',
  async (id: string) => {
    const response = await getVesselProducts(id);
    return response.data;
  }
);

export const fetchVesselBusList = createAsyncThunk(
  'selectedVessel/fetchVesselBusList',
  async (vesselId: string) => {
    const response = await getVesselBusList(vesselId);
    return response.data;
  }
);

export const fetchFilteredProperties = createAsyncThunk(
  'selectedVessel/fetchFilteredProperties',
  async (busName: string) => {
    const response = await getBusProperties(busName);
    return response.data;
  }
);

export const setVesselPropertyUpdateEnable = createAsyncThunk(
  'selectedVessel/handleVesselPropertyUpdateEnable',
  async ({ id, state }: { id: string; state: boolean }) => {
    await postSetVesselPropertyUpdateEnable(id, state);
  }
);

export const setVesselNotifications = createAsyncThunk(
  'selectedVessel/handleVesselNotifications',
  async ({ id, state }: { id: string; state: boolean }) => {
    await postSetUserNotificationsForVessel(id, state);
  }
);

export const setGpsReporting = createAsyncThunk(
  'selectedVessel/setGpsReporting',
  async ({ id, state }: { id: string; state: boolean }) => {
    await postSetGpsReporting(id, state);
  }
);

export const setMfrAccess = createAsyncThunk(
  'selectedVessel/setMfrAccess',
  async ({ id, state }: { id: string; state: boolean }) => {
    await postSetMfrAccess(id, state);
  }
);

export const fetchVesselLocationHistory = createAsyncThunk(
  'selectedVessel/fetchLocationHistory',
  async (vesselId: string) => {
    const response = await getVesselLocationHistory(vesselId);
    return response.data;
  }
);

export const fetchGeofenceParams = createAsyncThunk(
  'selectedVessel/fetchGeofenceParams',
  async (vesselId: string) => {
    const response = await getVesselGeofenceParams(vesselId);
    return response.data;
  }
);
